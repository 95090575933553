import './polyfills';
import React from "react";
import ReactDOM from "react-dom";
import ApolloClientCreator from "./ApolloClientCreator";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <ApolloClientCreator />,
    document.getElementById("root")
);

serviceWorker.unregister();