import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_SERVICES = gql`

    query getAllServicesAndPhysios{
        getAllServicesAndPhysios{
            services{
                service{
                    id
                    name
                    duration
                }
                physios
            }
            physios{
                physio{
                    id
                    name
                }
                services
            }
        }
    }`;

function GetServices() {
    //executing the query
    const {loading, data, error} = useQuery(GET_SERVICES)

    if (loading) {
        return "LOADING";
    }

    if (error) {
        // if (error.networkError)
        // {
        //     return 'The server is currently down temporarily. Please try again later and contact the system administrators if you still see this issue. You can book via phone on +356 99 424 404';
        // }
        // else
        // {
        //     error.graphQLErrors.forEach(err => {
        //         return err.message;
        //     });
        // }
        return 'Service is temporarily unavailable. We apologize for any inconvenience caused. You can book via phone on +356 99 424 404';
    }
    return data.getAllServicesAndPhysios;
}

export default GetServices;
