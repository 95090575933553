
import gql from "graphql-tag"
const GRAPHQL_PATH = "http://localhost:4000/graphql";

const GET_SERVICE_DETAILS = gql`
    query getService($id: Int!)
    {
        getService(id: $id){
            id
            name
            duration
        }
        getPhysiosWhoPerformService(id:$id){
            id
            name
        }
    }
`;

const DEL_SERVICE = gql`
    mutation delService($id: Int!) {
        deleteService(id: $id)
    }
`;

const EDIT_SERVICE = gql`
    mutation editService($id: Int!, $name: String, $duration: Int, $PhysioIds: [Int]) {
        editService(id: $id, name: $name, duration: $duration, PhysioIds: $PhysioIds){
            id
        }
    }
`;
const ADD_SERVICE = gql`
    mutation addService($name: String!, $duration: Int!,$PhysioIds: [Int]) {
        addService(name: $name, duration: $duration, PhysioIds: $PhysioIds){
            id
        }
    }
`;



const GET_PHYSIO_DETAILS = gql`
    query getPhysio($id: Int!)
    {
        getPhysio(id: $id){
            id
            name
            email
            calendar_reference
        }
        getServicesDoneByPhysio(id:$id){
            id
            name
        }
    }
`;

const DEL_PHYSIO = gql`
    mutation delPhysio($id: Int!) {
        deletePhysio(id: $id)
    }
`;

const EDIT_PHYSIO = gql`
    mutation editPhysio($id: Int!, $name: String, $calendar_reference: String, $email: String, $ServiceIds: [Int]) {
        editPhysio(id: $id, name: $name, calendar_reference: $calendar_reference, email: $email,ServiceIds: $ServiceIds){
            id
        }
    }
`;
const ADD_PHYSIO = gql`
    mutation addPhysio($name: String!, $calendar_reference: String!, $email: String!, $ServiceIds: [Int]) {
        addPhysio(name: $name, calendar_reference: $calendar_reference, email: $email, ServiceIds: $ServiceIds){
            id
        }
    }
`;
const ADJUST_HOURS = gql`
    mutation adjustHours($openingHours: Time!, $closingHours: Time!) {
        adjustHours(openingHours: $openingHours, closingHours: $closingHours)
    }
`;
const ADJUST_CONCURRENT_APPOINTMENTS = gql`
    mutation adjustConcurrentAppointments($concurrentAppointments: Int!) {
        adjustConcurrentAppointments(concurrentAppointments: $concurrentAppointments)
    }
`;
const ADJUST_SMS_MESSAGE = gql`
    mutation adjustSMSMessage($SMS: String!) {
        adjustSMSMessage(SMS: $SMS)
    }
`;

const GET_CONCURRENT_APPOINTMENTS = gql`
    query getConcurrentAppointments
    {
        getConcurrentAppointments{
            concurrent_apts
        }
    }
`;

const GET_AVAILABILITIES = gql`

    query getAvailabilities($doctor_id:[Int]!, $service_duration:Int!, $startDate:Date!, $endDate:Date!)
    {
        getAvailabilities(doctor_id: $doctor_id, service_duration: $service_duration, startDate: $startDate, endDate: $endDate){
            physio_id
            time
        }
    }

`;
const BOOK = gql`
    mutation bookAppointment($startDate: Date, $service_id: Int!, $doctor_id: Int!,$name: String!, $phone: String!, $email: String, $captcha_token: String,  $first_time: Boolean!, $note: String) {
        bookAppointment(startDate: $startDate, service_id: $service_id, doctor_id: $doctor_id, name: $name, phone: $phone,  email: $email, captcha_token: $captcha_token, first_time: $first_time, note: $note)
    }
`;

export{
    GRAPHQL_PATH,
    GET_SERVICE_DETAILS,
    DEL_SERVICE,
    EDIT_SERVICE,
    ADD_SERVICE,
    GET_PHYSIO_DETAILS,
    DEL_PHYSIO,
    EDIT_PHYSIO,
    ADD_PHYSIO,
    ADJUST_HOURS,
    ADJUST_CONCURRENT_APPOINTMENTS,
    ADJUST_SMS_MESSAGE,
    GET_CONCURRENT_APPOINTMENTS,
    GET_AVAILABILITIES,
    BOOK
}