import React, {useEffect, useState} from "react";
import moment from 'moment'
import 'moment-timezone';
import {Scrollbars} from 'react-custom-scrollbars';
import Card from '@material-ui/core/Card';
import {Mutation} from "react-apollo"
import './App.css';
import logo from './logo_cropped.png';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import GetServices from "./getServices";
import Alert from '@material-ui/lab/Alert';
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_AVAILABILITIES, BOOK} from "./typedefs";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {withStyles} from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import RoomIcon from '@material-ui/icons/Room';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from '@material-ui/core/Checkbox';
import validator from "validator";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

function StepOne(props) {

    const data = props.data
    let setDuration = props.setDuration
    let service = props.service
    let setService = props.setService
    let physio = props.physio
    let setPhysio = props.setPhysio

    const handleServiceChange = (event) => {
        setDuration((data['services'].filter(obj => {
            return obj.service.id === event.target.value
        }))[0].service.duration)
        setService(event.target.value);
        setPhysio((data['services'].filter(obj => {
            return obj.service.id === event.target.value
        }))[0].physios);
    };

    const handlePhysioChange = (event) => {
        setPhysio(event.target.value);
    };

    return (
        <div className="step_one">
            <FormControl variant="outlined" style={{width: "500px", maxWidth: "65vw", marginBottom: "30px"}}>
                <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={service}
                    onChange={handleServiceChange}
                    label="Service"
                    required
                >
                    {data['services'] && (data['services']).map(row => (
                        <MenuItem key={(row['service'].id)} value={(row['service']).id}>{(row['service']).name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <div className="break"/>

            <FormControl variant="outlined" style={{width: "500px", maxWidth: "65vw", marginBottom: "30px"}}
                         disabled={service === ''}>
                <InputLabel id="demo-simple-select-outlined-label">Clinician</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={physio}
                    onChange={handlePhysioChange}
                    label="Clinician"
                    required
                >

                    {
                        data['physios'] && service !== '' &&
                        <MenuItem value={(data['services'].filter(obj => {
                            return obj.service.id === service
                        }))[0].physios}>Any</MenuItem>
                    }
                    {
                        data['physios'] && (data['physios']).map(function (row) {
                                if (row['services'].includes(service)) {
                                    return <MenuItem value={(row['physio']).id}>{(row['physio']).name}</MenuItem>
                                }
                            }
                        )
                    }
                </Select>
            </FormControl>
        </div>
    )
}

function StepTwo(props) {
    const [getAvailabilities, {data, error, loading}] = useLazyQuery(GET_AVAILABILITIES)
    const handleTimeChange = (time) => {
        props.setPhysio(data['getAvailabilities'].filter(obj => {
            return obj.time === time
        })[0].physio_id)
        props.setTime(time)
    }

    const handleDateChange = (event) => {
        //refresh time
        props.setTime('');
        props.changeDate(event);
        //To get date until 'T' and without changing to UTC
        let dateFromString = new Date(event.getTime() - (event.getTimezoneOffset() * 60000)).toISOString().slice(0, 11)
        getAvailabilities({
            variables: {
                doctor_id: props.physio,
                service_duration: props.duration,
                startDate: dateFromString + "00:00:00+02:00",
                endDate: dateFromString + "23:59:59+02:00"
            }
        })
    };

    useEffect(() => {
        getAvailabilities({
            variables: {
                doctor_id: props.physio,
                service_duration: props.duration,
                startDate: new Date(props.date.getTime() - (props.date.getTimezoneOffset() * 60000)).toISOString().slice(0, 11) + "00:00:00+02:00",
                endDate: new Date(props.date.getTime() - (props.date.getTimezoneOffset() * 60000)).toISOString().slice(0, 11) + "23:59:59+02:00"
            }
        })
    }, [])

    const StyledListItem = withStyles({
        root: {
            "&.Mui-selected": {
                color: "#fff",
                backgroundColor: "#3f51b5",
            },
            "&.Mui-selected:hover": {
                color: "#fff",
                backgroundColor: "#3f51b5",
            }
        }
    })(ListItem);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 305,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        },
    }));

    const classes = useStyles();

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    return (
        <div className="step_two">
            <div className="calendar">
                <Card>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            orientation="landscape"
                            disablePast
                            disableToolbar
                            variant="static"
                            openTo="date"
                            value={props.date}
                            maxDate={aYearFromNow}
                            onChange={handleDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </Card>
            </div>
            <div className="time">
                <Card style={{height: "100%"}}>
                    <FormControl variant="outlined" style={{width: "100%", height: "100%"}}>
                        <List className={classes.root} subheader={<li/>} style={{width: "100%", height: "100%"}}>
                            <Scrollbars>
                                {
                                    data == null  &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: "40%"
                                    }}>
                                        <Loader
                                            type="Oval"
                                            color="#333333"
                                            height='100%'
                                            width='100%'
                                            timeout={30000} //30 secs
                                        />
                                    </div>
                                }
                                {
                                    data && (props.date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: "20px"
                                    }}>
                                        <h3 style={{color: "rgba(0, 0, 0, 0.9)", fontSize: "16px"}}>
                                            For same day bookings please call: +356 99 424 404
                                        </h3>
                                    </div>
                                }
                                {
                                    ((data && (props.date.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)) && data['getAvailabilities'].length < 1) || error) &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: "20px"
                                    }}>
                                        <h3 style={{color: "rgba(0, 0, 0, 0.9)", fontSize: "16px"}}>
                                            No times available. Please select a different date.
                                        </h3>
                                    </div>

                                }
                                {
                                    data && (props.date.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)) && (data['getAvailabilities']).map(function (sectionId) {
                                        return <li key={`section-${sectionId.time}`} className={classes.listSection}>
                                            <ul className={classes.ul}>
                                                <StyledListItem button selected={sectionId.time === props.time}
                                                                onClick={() => handleTimeChange(sectionId.time)}
                                                                key={`item-${sectionId}-${sectionId}`}>
                                                    <ListItemText
                                                        primary={(new Date(sectionId.time).toLocaleString('en-GB', {
                                                            timeZone: 'Europe/Malta',
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        }))}/>
                                                </StyledListItem>
                                            </ul>
                                        </li>
                                    })
                                }
                            </Scrollbars>
                        </List>
                    </FormControl>
                </Card>
            </div>
        </div>
    );
}

function StepThree(props) {

    const handleFirstTimeChange = (event) => {
        props.setFirstTime(event.target.checked);
    };

    return (
        <div className="step_three">
            <div className="details_div">
                <TextField id="outlined-basic" label="Name" variant="outlined" value={props.name} onChange={(e) => {
                    props.setName(e.target.value)
                }} style={{width: "100%"}} inputProps={{
                    autocomplete: 'off',
                    maxLength: 30
                }} required/>
            </div>
            <div className="break"/>
            <div className="details_div">
                <MuiPhoneNumber
                    inputProps={{
                        autocomplete: 'off',
                    }}
                    name="phone"
                    label="Phone Number"
                    data-cy="user-phone"
                    regions={'europe'}
                    defaultCountry={'mt'}
                    disableAreaCodes = {true}
                    value={props.phoneNumber}
                    onChange={(e) => props.setPhoneNumber(e)}
                    variant="outlined"
                    countryCodeEditable={false}
                    style={{width: "100%"}}
                    error={(props.phoneNumber.startsWith("+356")) && (props.phoneNumber.length !== 12)}
                    required
                />
            </div>
            <div className="break"/>
            <div className="details_div">
                <TextField id="outlined-basic" label="Email" variant="outlined" error={(props.email !== '') && (!validator.isEmail(props.email))} onChange={(e) => {
                    props.setEmail(e.target.value)
                }} style={{width: "100%"}} inputProps={{
                    autocomplete: 'off',
                    maxLength: 254
                }}/>
            </div>
            <div className="break"/>
            <div className="details_div">
                <TextField id="outlined-basic" label="Note" variant="outlined" onChange={(e) => {
                    props.setNote(e.target.value)
                }} style={{width: "100%"}} inputProps={{
                    autocomplete: 'off',
                    maxLength: 254
                }}/>
            </div>
            <div className="break"/>
            <div className="details_div">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.firstTime}
                            onChange={handleFirstTimeChange}
                            color="primary"
                        />
                    }
                    label="First Appointment"
                />
            </div>
        </div>
    )

}

function GetStepContent(data, date, changeDate, time, setTime, name, setName, email, setEmail, note, setNote, phoneNumber, setPhoneNumber, service, setService, physio, setPhysio, duration, setDuration, firstTime, setFirstTime, step) {

    switch (step) {
        case 0:
            return <StepOne data={data} service={service} setService={setService} physio={physio} setPhysio={setPhysio}
                            duration={duration} setDuration={setDuration}/>
        case 1:
            return <StepTwo date={date} changeDate={changeDate} time={time} setTime={setTime} service={service}
                            setService={setService} physio={physio} setPhysio={setPhysio} duration={duration}/>;
        case 2:
            return <StepThree name={name} setName={setName} email={email} setEmail={setEmail} note={note} setNote={setNote} phoneNumber={phoneNumber}
                              setPhoneNumber={setPhoneNumber} firstTime={firstTime} setFirstTime={setFirstTime}/>;
        default:
            return 'Error: Unknown Step';
    }
}

function Body() {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const data = GetServices()
    const [date, changeDate] = useState(tomorrow);
    const [time, setTime] = React.useState('');
    const [service, setService] = React.useState('');
    const [physio, setPhysio] = React.useState('');
    const [duration, setDuration] = React.useState(-1);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Select service', 'Select Time of Appointment', 'Enter Details'];
    const [error, setError] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [firstTime, setFirstTime] = React.useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    if(data === "LOADING"){
        return(
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh"
            }}>
                <Loader
                    type="Oval"
                    color="#333333"
                    timeout={30000} //30 secs
                />
            </div>
        )
    }else {
        return (
            <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {((typeof data === 'string' || data instanceof String) && data !== 'LOADING') &&
                    <Dialog
                        open={open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Service is currently down"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{data}</DialogContentText>
                        </DialogContent>
                    </Dialog>
                    }
                    {error !== '' &&
                    <Dialog
                        open={open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"There was a problem with your request"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
                        </DialogContent>
                    </Dialog>
                    }
                    {activeStep === steps.length ? (
                        <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
                            <div style={{width: "800px", maxWidth: "80vw",}}>
                                <Alert severity="success">
                                    <p style={{margin: "0"}}>Your booking has been confirmed.</p>
                                </Alert>
                                <div className="details_confirmation">
                                    <div style={{textAlign: "left", flexGrow: "1", padding: "20px 20px 6px 20px"}}>
                                        <h5 style={{margin: "0", fontSize: "18px"}}>Appointment Details</h5>
                                        <p className="details_p">Service: {(data['services'].filter(obj => {
                                            return obj.service.id === service
                                        }))[0].service.name}</p>
                                        <p className="details_p">Clinician: {(data['physios'].filter(obj => {
                                            return obj.physio.id === physio
                                        }))[0].physio.name}</p>
                                        <p className="details_p">Date: {moment(time).tz("Europe/Malta").format("ddd DD MMM") + " at " + moment(time).tz("Europe/Malta").format("HH:mm")}</p>
                                    </div>
                                    <div style={{textAlign: "left", flexGrow: "1", padding: "20px 20px 6px 20px"}}>
                                        <h5 style={{margin: "0", fontSize: "18px"}}>Personal Details</h5>
                                        <p className="details_p">Name: {name}</p>
                                        <p className="details_p">Phone: {phoneNumber}</p>
                                        {email !== '' &&
                                        <p className="details_p">Email: {email}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Typography component={'span'}
                                className={classes.instructions}>{GetStepContent(data, date, changeDate, time, setTime, name, setName, email, setEmail, note, setNote, phoneNumber, setPhoneNumber, service, setService, physio, setPhysio, duration, setDuration, firstTime, setFirstTime, activeStep)}
                            </Typography>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                >
                                    Back
                                </Button>

                                <Mutation mutation={BOOK}>
                                    {(bookAppointment, {loading}) => {
                                        return (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled=
                                                    {
                                                        (activeStep === 0 && (service === '' || physio === ''))
                                                        ||
                                                        (activeStep === 1 && (time === ''))
                                                        ||
                                                        (activeStep === 2 && (name === '' || (phoneNumber === '' || ((phoneNumber.startsWith("+356")) && (phoneNumber.length !== 12))) || (email !== '' && !validator.isEmail(email))))
                                                        ||
                                                        (loading)
                                                    }
                                                onClick=
                                                    {() => {
                                                        if (activeStep === steps.length - 1) {
                                                            bookAppointment({
                                                                variables: {
                                                                    startDate: time,
                                                                    service_id: service,
                                                                    doctor_id: physio,
                                                                    name: name,
                                                                    phone: phoneNumber,
                                                                    email: email,
                                                                    note: note,
                                                                    captcha_token: "captcha_token",
                                                                    first_time: firstTime
                                                                }
                                                            }).then((result) => {
                                                                if (result.data.bookAppointment)
                                                                    handleNext()
                                                                else
                                                                    setError("An error has occurred and the appointment has not been booked. Please call +356 99 424 404 to confirm your booking.")

                                                                //window.location.reload()

                                                            }).catch(err => {

                                                                // if (err.networkError) {
                                                                //     setError('The server is currently down temporarily. Please try again later and contact the system administrators if you still encounter this issue. You can book via phone on +356 99 424 404');
                                                                // } else {
                                                                //     err.graphQLErrors.forEach(err => {
                                                                //         setError(err.message);
                                                                //     });
                                                                // }

                                                                setError('Service is temporarily unavailable. We apologize for any inconvenience caused. You can book via phone on +356 99 424 404');
                                                            })
                                                        } else {
                                                            handleNext()
                                                        }
                                                    }

                                                    }>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        )
                                    }}
                                </Mutation>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function App() {
    return (
        <div className="App">
            <div className="header">
                <div className="header_flex">
                    <div className="header_left">
                        <a href=''>
                            <img src={logo}/>
                        </a>
                        <div className="booking_portal">
                            <h1 style={{marginLeft: "10px", fontSize: "26px"}}>BOOKING PORTAL</h1>
                        </div>
                    </div>
                    <div className="call">
                        <div className="call_inner">
                            <CallIcon style={{fontSize: 26}}/>
                            <h3 style={{marginLeft: "5px"}}>+356 99 424 404</h3>
                        </div>
                        <div className="call_inner_right">
                            <RoomIcon style={{fontSize: 26}}/>
                            <h3 style={{marginLeft: "5px"}}>3rd Floor, National Pool Complex, Tal Qroqq Gzira</h3>
                        </div>
                        <div className="small_screen">
                            <RoomIcon style={{fontSize: 26}}/>
                            <h3 style={{marginLeft: "5px"}}>3rd Floor, National Pool</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="body_content">
                    <Body/>
                </div>
            </div>

            <footer>
                <div className="copyright">
                    <h4>© 2021 Physio Suite. <a href="https://www.neuralsolutions.eu/" target="_blank"
                                                       className="neural_solutions"> Website Development in Malta - Neural Solutions </a></h4>
                </div>
                <div className="footer_menu">
                    <a href='http://www.thesuite.physio/' target="_blank">
                        <h4 className="footer_h4">Website</h4>
                    </a>
                    <a href='http://www.thesuite.physio/' target="_blank">
                        <h4 style={{marginLeft: "20px", marginRight: "20px"}} className="footer_h4">Contact Info</h4>
                    </a>
                    <a href='http://www.thesuite.physio/' target="_blank">
                        <h4 className="footer_h4">Privacy Policy</h4>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default App;
