import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import React, { useEffect, useState } from "react";
import App from './App';
import { setContext } from 'apollo-link-context';
import {ApolloClient} from "apollo-client"
import {ApolloProvider, Mutation} from "react-apollo"
import {graphQL} from "./auth_config.json"
const apolloCache = new InMemoryCache()
const uploadLink = createUploadLink({
	uri: graphQL,
	headers: {
		"keep-alive": "true"
	}
})

const ApolloClientCreator = () => {

	const client = new ApolloClient({
		cache: apolloCache,
		link: uploadLink,
		onError: ({ graphQLErrors, networkError, operation, response }) => {
			if (networkError) {
				console.log("A network error has occurred.")
			}else if(graphQLErrors){
				console.log("A graphql error has occurred.")
			}else{
				console.log("Some error occurred.")
			}
		},
	});
	return (

		<ApolloProvider client = {client}>
			<App />
		</ApolloProvider>
	);
};

export default ApolloClientCreator;